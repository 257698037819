import React, { MouseEvent, useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import {
  GatsbyFluidImage
} from '../templates/template-types'

import Category from '../components/category'
import Layout from '../components/layout/layout'
import ResourceCategories from '../components/resource-categories'
import SEO from '../components/seo'

import ftoLogo from '../images/educator-resource-center/classroom-instruction.svg'
import tplLogo from '../images/educator-resource-center/professional-learning.svg'
import ldLogo from '../images/educator-resource-center/leadership-work.svg'


type Frontmatter = {
  alt: string,
  date: string,
  additionalTags: string[],
  cardDescription: string,
  image: GatsbyFluidImage,
  canonicalUrl: string,
  seoDescription: string,
  seoLang: string,
  sortOrder: number,
  title: string,
  type: string,
  page: string
}

type Node = {
  excerpt: string,
  frontmatter: Frontmatter
}

type Edge = {
  node: Node
}

type CategoryItem = {
  alt: string,
  id: string,
  image: string,
  path: string,
  title: string,
  variant: string
}

const categories: CategoryItem[] = [
  {
    alt: 'Stick figures around a whiteboard',
    id: 'tpl',
    image: tplLogo,
    path: 'teacher-professional-learning',
    title: 'Teacher Professional Learning',
    variant: 'yellow'
  },
  {
    alt: 'Clipboard',
    id: 'ld',
    image: ldLogo,
    path: 'leadership-development',
    title: 'Leadership Development',
    variant: 'red'
  },
  {
    alt: 'Stick figures around a table',
    id: 'fto',
    image: ftoLogo,
    path: 'field-test-opportunities',
    title: 'Field-Test Opportunities',
    variant: 'violet'
  }
]

const doesProgramTypeExist = (type: string, programs: Edge[]): boolean => {
  for (let i: number = 0; i < programs.length; i++) {
    if (programs[i].node.frontmatter.type === type) {
      return true
    }
  }

  return false
}

const UpcomingProgramsPage = ({ data }) => {
  const programs: Edge[] = data.allMdx.edges

  const [tplPrograms, setTplPrograms] = useState<boolean>(false)
  const [ldPrograms, setLdPrograms] = useState<boolean>(false)
  const [ftoPrograms, setFtoPrograms] = useState<boolean>(false)

  useEffect(() => {
    setTplPrograms(doesProgramTypeExist('Teacher Professional Learning', programs))
    setLdPrograms(doesProgramTypeExist('Leadership Development', programs))
    setFtoPrograms(doesProgramTypeExist('Field-Test Opportunities', programs))
  }, [])

  return (
    <React.Fragment>
      <SEO
        title="Science Education Leadership Programs & Training"
        description="BSCS Science Learning is an independent nonprofit offering science education professional development training & leadership programs across the US."
        canonicalUrl="https://bscs.org/upcoming-programs/"
      />
      <Layout>
        <section className="section" style={{ padding: '.75rem 1.5rem' }}>
          <div id="upcoming-filters">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link className='inline-anchor' to='/'>Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                Upcoming Programs
              </BreadcrumbItem>
            </Breadcrumb>
            <PageTitle>Upcoming Programs</PageTitle>
            <TitleBorder />
            <div className="w-full" style={{marginBottom: '2rem'}}>
              <p><strong>Find upcoming programs in the following areas:</strong></p>
            </div>
            <div className='flex justify-center'>
              {categories.map((category: CategoryItem, index: number) => {
                return (
                  <Category
                    category={category}
                    key={`category=${index}`}
                    onClick={(e: MouseEvent, category: Category) => {
                      e.preventDefault()

                      if (category.path) {
                        document?.getElementById(category.path)?.scrollIntoView({behavior: "smooth", block: "start"})
                      }
                    }}
                  />
                )
              })}
            </div>
            <h2 id="teacher-professional-learning">Teacher Professional Learning</h2>
            <div style={{ marginBottom: '2rem' }}>
              <div>
                <p>
                  Are you a teacher who is looking to enhance your science content knowledge or instructional practices? Would you like a deeper understanding of the Next Generation Science Standards (NGSS)? Have you heard about our signature <Link to="/our-work/rd-programs/stella-science-teachers-learning-from-lesson-analysis">STeLLA</Link> approach and want to learn how to use high-leverage science teaching strategies through video-based lesson analysis?
                </p>
                <p>
                  BSCS Science Learning offers a wide range of professional learning opportunities for teachers. See what’s currently available below. If we do not presently offer a relevant opportunity for you, please feel free to reach out about how you can <Link to="/connect/work-with-us/" title="Science Education Instructional Materials & Research">work with us</Link> in the future.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div>
            <div style={{ marginBottom: '3rem' }}>
              { !tplPrograms &&
                <div className="w-full">
                  <Alert variant="indigo">There are no upcoming teacher professional learning programs at this time.</Alert>
                </div>
              }
              { programs &&
                <div className="flex flex-wrap">
                  {programs.map((edge, index) => {
                    // let data_filter = JSON.parse(JSON.stringify(edge.node.frontmatter))
                    // data_filter['excerpt'] = edge.node.excerpt
                    if (edge.node.frontmatter.type === 'Teacher Professional Learning') {
                      return (
                        <div
                          className="flex justify-center md:w-1/2 lg:w-1/3 xl:w-1/4 w-full"
                          key={`up-${index}`}
                        >
                          <VerticalCard
                            id={`program-${index}`}
                            className="flex"
                          >
                            <Img
                              fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                              className="h-64 rounded-t w-full"
                              alt={edge.node.frontmatter.alt}
                            />
                            <VerticalCardBody>
                              <h3>{edge.node.frontmatter.title}</h3>
                              <div>
                                <p>
                                  {edge.node.frontmatter.cardDescription}
                                  {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                                </p>
                              </div>
                            </VerticalCardBody>
                            <div className="flex items-end justify-end p-5 w-full">
                              <Link
                                to={`/upcoming-programs/teacher-professional-learning/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                              >
                                <Button title='More Information'>More Information</Button>
                              </Link>
                            </div>
                          </VerticalCard>
                        </div>
                      )
                    }
                  })}
                </div>
              }
            </div>
          </div>
        </section>
        <section className="section mb-5 w-full flex">
          <Button
            className='ml-auto rounded-full'
            onClick={(e: MouseEvent) => document?.getElementById('upcoming-filters')?.scrollIntoView({behavior: "smooth", block: "start"})}
            title='Back to Top'
            variant='orange'
          >
            Back to Top <i className='fad fa-arrow-alt-up' />
          </Button>
        </section>
        <section className="section" style={{ padding: '.75rem 1.5rem' }}>
          <div>
            <h2 id="leadership-development">Leadership Development</h2>
            <div style={{ marginBottom: '2rem' }}>
              <div>
                <p>
                  Are you a science education leader or provider of professional learning services who is looking to support teachers’ professional growth? Would you like a deeper understanding of how to help teachers enact the Next Generation Science Standards (NGSS)? Could you benefit from tools and processes that prepare teams of educators to evaluate, select, and implement instructional materials designed for next generation science?
                </p>
                <p>
                  BSCS Science Learning offers a wide range of professional learning opportunities for science education leaders and professional learning providers. See what’s currently available below. If we do not presently offer a relevant opportunity for you, please feel free to reach out about how you can <Link to="/connect/work-with-us/" title="Science Education Instructional Materials & Research">work with us</Link> in the future.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div>
            <div style={{ marginBottom: '3rem' }}>
              { !ldPrograms &&
                <div>
                  <Alert variant="indigo">There are no upcoming leadership development programs at this time.</Alert>
                </div>
              }
              { programs && ldPrograms &&
                <div className="flex flex-wrap">
                  {programs.map((edge, index) => {
                    if (edge.node.frontmatter.type === "Leadership Development") {
                      return (
                        <div
                          className="flex justify-center md:w-1/2 lg:w-1/3 xl:w-1/4 w-full"
                          key={`ld-${index}`}
                        >
                          <VerticalCard
                            className="flex"
                            id={`program-${index}`}
                          >
                            <Img
                              alt={edge.node.frontmatter.alt}
                              className="h-64 rounded-t w-full"
                              fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                            />
                            <VerticalCardBody>
                              <h3>{edge.node.frontmatter.title}</h3>
                              <p>
                                {edge.node.frontmatter.cardDescription}
                                {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                              </p>
                            </VerticalCardBody>
                            <div className="flex items-end justify-end p-5 w-full">
                              <Link
                                to={`/upcoming-programs/leadership-development/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                              >
                                <Button>More Information</Button>
                              </Link>
                            </div>
                          </VerticalCard>
                        </div>
                      )
                    }
                  })}
                </div>
              }
            </div>
          </div>
        </section>
        <section className="section mb-5 w-full flex">
          <Button
            className='ml-auto rounded-full'
            onClick={(e: MouseEvent) => document?.getElementById('upcoming-filters')?.scrollIntoView({behavior: "smooth", block: "start"})}
            title='Back to Top'
            variant='red'
          >
            Back to Top <i className='fad fa-arrow-alt-up' />
          </Button>
        </section>
        <section className="section" style={{ padding: '.75rem 1.5rem' }}>
          <div>
            <h2 id="field-test-opportunities">Field-Test Opportunities</h2>
            <div style={{ marginBottom: '2rem' }}>
              <div>
                <p>
                  Are you looking for new K-12 science curriculum materials? Are you interested in newly-developed teacher professional learning programs? Would your school or district be willing to test these materials and approaches in classroom settings?
                </p>
                <p>
                  BSCS Science Learning offers a wide range of field-test opportunities for teachers, principals, and district leaders to consider. An important part of our process is to elicit teacher and student feedback on the usability and feasibility of our materials and programs. See what’s currently available below. If we do not presently offer a relevant opportunity for you, please feel free to reach out about how you can <Link to="/connect/work-with-us/" title="Science Education Instructional Materials & Research">work with us</Link> in the future.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div>
            <div style={{ marginBottom: '3rem' }}>
              { !ftoPrograms &&
                <div>
                  <Alert variant="indigo">There are no upcoming field-test opportunities at this time.</Alert>
                </div>
              }
              { programs && ftoPrograms &&
                <div className="flex flex-wrap">
                  {programs.map((edge, index) => {
                    if (edge.node.frontmatter.type === "Field-Test Opportunities") {
                      return (
                        <div
                          key={`fto-${index}`}
                          className="flex justify-center md:w-1/2 lg:w-1/3 xl:w-1/4 w-full"
                        >
                          <VerticalCard
                            className="flex"
                            id={`program-${index}`}
                          >
                            <Img
                              fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                              className="h-64 rounded-t w-full"
                              alt={edge.node.frontmatter.alt}
                            />
                            <VerticalCardBody>
                              <h3>{edge.node.frontmatter.title}</h3>
                              <p>
                                {edge.node.frontmatter.cardDescription}
                                {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                              </p>
                            </VerticalCardBody>
                            <div className="flex items-end justify-end p-5 w-full">
                              <Link
                                to={`/upcoming-programs/field-test-opportunities/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                              >
                                <Button>More Information</Button>
                              </Link>
                            </div>
                          </VerticalCard>
                        </div>
                      )
                    }
                  })}
                </div>
              }
            </div>
          </div>
        </section>
        <section className="section mb-5 w-full flex">
          <Button
            className='ml-auto rounded-full'
            onClick={(e: MouseEvent) => document?.getElementById('upcoming-filters')?.scrollIntoView({behavior: "smooth", block: "start"})}
            title='Back to Top'
            variant='violet'
          >
            Back to Top <i className='fad fa-arrow-alt-up' />
          </Button>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default UpcomingProgramsPage

export const query = graphql`
  {
    allMdx(
      filter: {frontmatter: { status: {eq: "Ready"}, page: {eq: "upcoming-programs"}}}
      sort: {
        fields: [frontmatter___sortOrder, frontmatter___date]
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          frontmatter {
            alt
            date(formatString: "MMMM DD, YYYY")
            additionalTags
            cardDescription
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            canonicalUrl
            seoDescription
            seoLang
            sortOrder
            title
            type
            page
          }
        }
      }
    }
  }
`

